import React from 'react';
import { X } from 'lucide-react';
import { LoginForm } from './auth/LoginForm';
import { RegisterForm } from './auth/RegisterForm';
import RequestResetForm from './auth/RequestResetForm';
import { useLoginModal } from '../context/LoginModalContext';

export const LoginModal = () => {
  const { isOpen, closeModal, modalContent, setModalContent } = useLoginModal();

  if (!isOpen) return null;

  const handleModalClick = (e) => {
    e.stopPropagation();
  };

  const switchToRegister = () => {
    setModalContent('register');
  };

  const switchToLogin = () => {
    setModalContent('login');
  };

  const switchToForgotPassword = () => {
    setModalContent('forgot-password');
  };

  return (
    <div 
      className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center"
      onClick={closeModal}
    >
      <div 
        className="bg-white rounded-lg shadow-xl relative max-w-md w-full m-4"
        onClick={handleModalClick}
      >
        <button 
          onClick={closeModal}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 p-2"
        >
          <X size={20} />
        </button>
        <div className="p-6">
          {modalContent === 'login' && (
            <>
              <LoginForm 
                onSuccess={closeModal} 
                switchToForgotPassword={switchToForgotPassword}
              />
              <div className="mt-4 text-center">
                <span className="text-sm text-gray-600">Don't have an account? </span>
                <button
                  onClick={switchToRegister}
                  className="text-sm font-medium text-indigo-600 hover:text-indigo-500"
                >
                  Register here
                </button>
              </div>
            </>
          )}
          
          {modalContent === 'register' && (
            <>
              <RegisterForm onSuccess={closeModal} />
              <div className="mt-4 text-center">
                <span className="text-sm text-gray-600">Already have an account? </span>
                <button
                  onClick={switchToLogin}
                  className="text-sm font-medium text-indigo-600 hover:text-indigo-500"
                >
                  Login here
                </button>
              </div>
            </>
          )}

          {modalContent === 'forgot-password' && (
            <>
              <RequestResetForm onSuccess={closeModal} />
              <div className="mt-4 text-center">
                <span className="text-sm text-gray-600">Remembered your password? </span>
                <button
                  onClick={switchToLogin}
                  className="text-sm font-medium text-indigo-600 hover:text-indigo-500"
                >
                  Login here
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};