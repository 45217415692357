import React, { useState, useEffect } from 'react';
import { Activity, Globe, Lock, Search, Copy, Check, List, X } from 'lucide-react';
import { useNetwork } from '../context/networkContext';
import ApiService from '../services/api';

const SearchInput = ({ value, onChange }) => (
  <div className="relative">
    <div className="absolute inset-y-0 left-3 flex items-center pointer-events-none">
      <Search size={20} className="text-gray-400" />
    </div>
    <input
      type="text"
      placeholder="Search producer..."
      className="w-64 pl-10 pr-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500"
      value={value}
      onChange={(e) => onChange(e.target.value)}
    />
  </div>
);

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div 
        className="fixed inset-0 bg-black bg-opacity-50"
        onClick={onClose}
      />
      <div className="relative bg-white rounded-lg max-w-2xl w-full mx-4 shadow-xl">
        {children}
      </div>
    </div>
  );
};

const P2PListModal = ({ isOpen, onClose, nodes }) => {
  const [copied, setCopied] = useState(false);
  
 // Obtener la fecha actual en el formato requerido
 const currentDate = new Date().toISOString().split('T')[0];
 const currentTime = new Date().toLocaleTimeString('en-US', { 
   hour: '2-digit', 
   minute: '2-digit',
   hour12: false 
 });
 
 // Crear el encabezado
 const header = `#### LIST CREATED ON ${currentDate} ${currentTime} by NodeInspector - Eosiomadrid\n`;
 
 // Filtrar solo los nodos activos y crear la lista de endpoints
 const activeEndpoints = nodes
   .filter(producer => producer.nodes.seed.active)
   .flatMap(producer => 
     producer.nodes.seed.endpoints.map(endpoint => 
       `p2p-peer-address = ${endpoint}`
     )
   );

 const endpointText = header + '\n' + activeEndpoints.join('\n');

 const handleCopy = async () => {
   try {
     await navigator.clipboard.writeText(endpointText);
     setCopied(true);
     setTimeout(() => setCopied(false), 2000);
   } catch (err) {
     console.error('Failed to copy:', err);
   }
 };

 return (
   <Modal isOpen={isOpen} onClose={onClose}>
     <div className="p-6">
       <div className="flex items-center justify-between mb-4">
         <h2 className="text-xl font-semibold text-gray-900">Active P2P Endpoints List</h2>
         <button 
           onClick={onClose}
           className="text-gray-400 hover:text-gray-500 transition-colors"
         >
           <X size={24} />
         </button>
       </div>
       
       <div className="bg-gray-50 p-4 rounded-lg max-h-96 overflow-y-auto font-mono text-sm">
         <div className="text-gray-600 mb-4">{header}</div>
         {activeEndpoints.map((endpoint, index) => (
           <div key={index} className="mb-1">{endpoint}</div>
         ))}
       </div>
       
       <div className="mt-4 flex justify-end">
         <button
           onClick={handleCopy}
           className={`flex items-center gap-2 px-4 py-2 rounded-lg border transition-colors ${
             copied 
               ? 'bg-green-50 text-green-600 border-green-200' 
               : 'bg-white text-gray-600 border-gray-200 hover:bg-gray-50'
           }`}
         >
           {copied ? (
             <>
               <Check size={16} />
               Copied!
             </>
           ) : (
             <>
               <Copy size={16} />
               Copy to Clipboard
             </>
           )}
         </button>
       </div>
     </div>
   </Modal>
 );
};

const EndpointsList = ({ title, endpoints, icon: Icon }) => {
  if (!endpoints || endpoints.length === 0) return null;
  
  return (
    <div className="space-y-1">
      <div className="flex items-center gap-2 text-sm text-gray-500">
        <Icon size={16} />
        <span className="font-medium">{title}:</span>
      </div>
      {endpoints.map((endpoint, index) => (
        <div key={index} className="pl-6 text-sm font-mono text-gray-900">
          {endpoint}
        </div>
      ))}
    </div>
  );
};

const NodeTypeView = ({ type, title, icon: Icon }) => {
  const { currentNetwork } = useNetwork();
  const [nodes, setNodes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const network = currentNetwork?.id || 'wax-mainnet';

  useEffect(() => {
    const fetchNodes = async () => {
      try {
        setLoading(true);
        const data = await ApiService.getBPData(network);
        
        const filteredNodes = data.producers.filter(producer => {
          const node = producer.nodes && producer.nodes[type];
          if (!node) return false;
          
          if (type === 'seed') {
            return node.endpoints && node.endpoints.length > 0;
          } else {
            return (node.http_endpoints && node.http_endpoints.length > 0) ||
                   (node.https_endpoints && node.https_endpoints.length > 0);
          }
        });

        const sortedNodes = filteredNodes.sort((a, b) => {
          const aActive = a.nodes[type].active;
          const bActive = b.nodes[type].active;
          if (aActive === bActive) return 0;
          return aActive ? -1 : 1;
        });

        setNodes(sortedNodes);
      } catch (err) {
        console.error('Error fetching nodes:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchNodes();
  }, [network, type]);

  const filteredNodes = nodes.filter(producer =>
    producer.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <Activity className="animate-spin h-8 w-8 text-indigo-500" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-red-500 text-center p-4">
        Error loading {title} nodes: {error}
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between mb-6">
        <div className="flex items-center gap-3">
          <Icon className="h-8 w-8 text-indigo-600" />
          <h1 className="text-2xl font-bold text-gray-900">{title} Nodes</h1>
          <span className="px-3 py-1 text-sm font-medium text-indigo-600 bg-indigo-50 rounded-full">
            {nodes.length} active
          </span>
          {type === 'seed' && (
            <button
              className="ml-4 flex items-center gap-2 px-4 py-2 rounded-lg border border-gray-200 hover:bg-gray-50 transition-colors"
              onClick={() => setIsModalOpen(true)}
            >
              <List size={16} />
              View for config.ini
            </button>
          )}
        </div>
        
        <SearchInput 
          value={searchTerm}
          onChange={setSearchTerm}
        />
      </div>

      {type === 'seed' && (
        <P2PListModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          nodes={nodes}
        />
      )}

      <div className="space-y-4">
        {filteredNodes.map(producer => {
          const nodeInfo = producer.nodes[type];
          
          return (
            <div key={producer.name} className="bg-white rounded-lg p-4 shadow-sm hover:shadow-md transition-shadow">
              <div className="flex flex-col gap-4">
                <div className="flex items-center gap-4">
                  <div className="w-10 h-10 overflow-hidden rounded-lg flex items-center justify-center bg-indigo-50">
                    {producer.logo ? (
                      <img 
                        src={producer.logo} 
                        alt={producer.name}
                        className="w-full h-full object-contain"
                      />
                    ) : (
                      <span className="text-indigo-500 font-semibold">BP</span>
                    )}
                  </div>

                  <div className="flex-1">
                    <div className="flex items-center gap-2">
                      <span className="font-medium text-gray-900">{producer.name}</span>
                      {producer.isTop21 && (
                        <span className="px-2 py-0.5 text-xs font-medium text-blue-600 bg-blue-50 rounded-full">
                          Top 21
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="flex items-center gap-2">
                    <span className={`w-2 h-2 rounded-full ${nodeInfo.active ? 'bg-green-500' : 'bg-red-500'}`} />
                    <span className={`text-sm ${nodeInfo.active ? 'text-green-600' : 'text-red-600'}`}>
                      {nodeInfo.active ? 'Active' : 'Inactive'}
                    </span>
                  </div>
                </div>

                <div className="pl-14 space-y-3">
                  {type === 'seed' ? (
                    <EndpointsList 
                      title="P2P Endpoints"
                      endpoints={nodeInfo.endpoints}
                      icon={Globe}
                    />
                  ) : (
                    <>
                      <EndpointsList 
                        title="HTTP Endpoints"
                        endpoints={nodeInfo.http_endpoints}
                        icon={Globe}
                      />
                      <EndpointsList 
                        title="HTTPS Endpoints"
                        endpoints={nodeInfo.https_endpoints}
                        icon={Lock}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          );
        })}

        {filteredNodes.length === 0 && (
          <div className="text-center text-gray-500 py-8">
            No {title} nodes found matching your criteria
          </div>
        )}
      </div>
    </div>
  );
};

export default NodeTypeView;