import React, { useState, useEffect } from 'react';
import { Activity, Radio, Server, History, Database, Box, Search } from 'lucide-react';
import { useNetwork } from '../context/networkContext';
import ApiService from '../services/api';

// Search Input Component
const SearchInput = ({ value, onChange }) => (
  <div className="relative">
    <div className="absolute inset-y-0 left-3 flex items-center pointer-events-none">
      <Search size={20} className="text-gray-400" />
    </div>
    <input
      type="text"
      placeholder="Search producer..."
      className="w-64 pl-10 pr-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500"
      value={value}
      onChange={(e) => onChange(e.target.value)}
    />
  </div>
);

const serviceConfig = {
  seed: {
    icon: <Radio size={32} />,
    name: 'Seed'
  },
  api: {
    icon: <Server size={32} />,
    name: 'API'
  },
  v1: {
    icon: <History size={32} />,
    name: 'V1'
  },
  v2: {
    icon: <Database size={32} />,
    name: 'V2'
  },
  atomic: {
    icon: <Box size={32} />,
    name: 'Atomic'
  }
};

const ServiceStatus = ({ type, status }) => (
  <div className="flex flex-col items-center">
    <div className={`${status ? 'text-green-500' : 'text-red-500'}`}>
      {serviceConfig[type].icon}
    </div>
    <span className={`text-xs mt-1 ${status ? 'text-green-500' : 'text-red-500'}`}>
      {serviceConfig[type].name}
    </span>
  </div>
);

const ProducerRow = ({ producer }) => {
  const countryCode = producer.country?.toLowerCase() || 'un';
  
  return (
    <div className="bg-white rounded-lg p-2 md:p-4 shadow-sm hover:shadow-md transition-shadow">
      <div className="grid grid-cols-6 md:grid-cols-12 items-center">
        {/* Producer Information - 5 columns */}
        <div className="col-span-6 md:col-span-5 flex items-center gap-2 md:gap-4">
          <span className="text-gray-500 font-medium w-8">#{producer.position}</span>
          <div className="w-8 h-8 md:w-10 md:h-10 overflow-hidden rounded-lg flex items-center justify-center bg-indigo-50">
            {producer.logo ? (
              <img 
                src={producer.logo} 
                alt={producer.name}
                className="w-full h-full object-contain"
              />
            ) : (
              <span className="text-indigo-500 font-semibold">PR</span>
            )}
          </div>
          <div className="flex items-center gap-1 md:gap-2">
            <span className="font-medium text-gray-900">{producer.name}</span>
            {producer.isTop21 && (
              <span className="px-2 py-0.5 text-xs font-medium text-blue-600 bg-blue-50 rounded-full">
                Top 21
              </span>
            )}
            {countryCode !== 'un' && (
              <img 
                src={`https://flagcdn.com/20x15/${countryCode}.png`}
                alt={producer.country}
                className="h-4"
              />
            )}            
          </div>
        </div>

        {/* Services - Remaining columns */}
        <div className="col-span-6 md:col-span-7 grid grid-cols-2 md:grid-cols-5 gap-2 md:gap-4">
          {Object.entries(producer.services || {}).map(([service, status]) => (
            <ServiceStatus 
              key={service}
              type={service}
              status={status}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default function Dashboard() {
  const { currentNetwork } = useNetwork();
  const [producers, setProducers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  const network = currentNetwork?.id || 'wax-mainnet';

  useEffect(() => {
    const fetchProducers = async () => {
      try {
        setLoading(true);
        const data = await ApiService.getBPData(network);
        
        // Transform the data to the format expected by the component
        const transformedProducers = data.producers.map(producer => ({
          ...producer,
          services: {
            seed: producer.nodes?.seed?.active || false,
            api: producer.nodes?.api?.active || false,
            v1: producer.nodes?.v1?.active || false,
            v2: producer.nodes?.v2?.active || false,
            atomic: producer.nodes?.atomic?.active || false
          }
        }));
        
        setProducers(transformedProducers);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchProducers();
  }, [network]);

  // Filter producers based on search term
  const filteredProducers = producers.filter(producer =>
    producer.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <Activity className="animate-spin h-8 w-8 text-indigo-500" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-red-500 text-center p-4">
        Error loading producers: {error}
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {/* Fixed Header Section - Mismo estilo que en Statistics */}
      <div 
        className="flex items-center justify-end sticky top-0 bg-white p-4 shadow-md z-10"
        style={{ position: 'sticky', top: 0 }}
      >
        <SearchInput 
          value={searchTerm}
          onChange={setSearchTerm}
        />
      </div>

      {/* Producers List Section */}
      <div className="space-y-3">
        {filteredProducers.map(producer => (
          <ProducerRow 
            key={producer.name}
            producer={producer}
          />
        ))}
        {filteredProducers.length === 0 && (
          <div className="text-center text-gray-500 py-8">
            No producers found matching your criteria
          </div>
        )}
      </div>
    </div>
  );
}
