import React, { createContext, useState, useContext } from 'react';

const NetworkContext = createContext();

export function NetworkProvider({ children }) {
  const [currentNetwork, setCurrentNetwork] = useState(null);

  return (
    <NetworkContext.Provider value={{ currentNetwork, setCurrentNetwork }}>
      {children}
    </NetworkContext.Provider>
  );
}

export function useNetwork() {
  return useContext(NetworkContext);
}
