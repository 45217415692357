// src/pages/UserDashboard.js
import React from 'react';
import { useAuth } from '../hooks/useAuth';

const UserDashboard = () => {
  const { user } = useAuth();

  return (
    <div className="min-h-screen bg-gray-50 py-12 px-4">
      <div className="max-w-7xl mx-auto">
        <h1 className="text-3xl font-bold text-gray-900">
          Hola {user?.email}
        </h1>
      </div>
    </div>
  );
};

export default UserDashboard;