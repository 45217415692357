import React from 'react';
import { Radio, Server, History, Database, Box } from 'lucide-react';
import NodeTypeView from '../../components/NodeTypeView';

// Definimos y exportamos cada componente individualmente
export const SeedNodes = () => (
  <NodeTypeView 
    type="seed"
    title="Seed"
    icon={Radio}
  />
);

export const ApiNodes = () => (
  <NodeTypeView 
    type="api"
    title="API"
    icon={Server}
  />
);

export const V1Nodes = () => (
  <NodeTypeView 
    type="v1"
    title="V1"
    icon={History}
  />
);

export const V2Nodes = () => (
  <NodeTypeView 
    type="v2"
    title="V2"
    icon={Database}
  />
);

export const AtomicNodes = () => (
  <NodeTypeView 
    type="atomic"
    title="Atomic"
    icon={Box}
  />
);