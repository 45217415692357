import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { 
  Activity,
  BarChart2,
  Radio,
  Server,
  Database,
  History,
  Box,
  Heart,
  X,
  BellRing
} from 'lucide-react';
import Header from './Header';

export default function DashboardLayout({ children }) {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const location = useLocation();

  const menuItems = {
    status: [
      { icon: <Activity size={20} />, text: 'Current Status', path: '/' },
      { icon: <BarChart2 size={20} />, text: 'Statistics', path: '/statistics' }
    ],
    nodes: [
      { icon: <Radio size={20} />, text: 'Seed', path: '/seed' },
      { icon: <Server size={20} />, text: 'API', path: '/api' },
      { icon: <History size={20} />, text: 'V1', path: '/v1' },
      { icon: <Database size={20} />, text: 'V2', path: '/v2' },
      { icon: <Box size={20} />, text: 'Atomic', path: '/atomic' }
    ],
    management: [
      { icon: <BellRing size={20} />, text: 'Subscriptions', path: '/subscriptions' }
    ]
  };

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <aside 
        className={`
          fixed inset-y-0 left-0 w-64 bg-indigo-700
          transform transition-transform duration-300 ease-in-out z-30
          ${sidebarOpen ? 'translate-x-0' : '-translate-x-full'}
          lg:relative lg:translate-x-0
        `}
      >
        {/* Logo Section */}
        <div className="flex items-center justify-center h-16 bg-indigo-800">
          <Heart size={24} className="text-indigo-300 mr-2" />
          <h1 className="text-2xl font-bold text-white tracking-wide">
            Node<span className="text-indigo-300">Inspector</span>
          </h1>
        </div>

        {/* Mobile close button */}
        <button
          className="lg:hidden absolute top-4 right-4 text-white"
          onClick={() => setSidebarOpen(false)}
        >
          <X size={20} />
        </button>

        {/* Navigation */}
        <nav className="mt-6 px-4">
          <div className="space-y-1">
            {menuItems.status.map((item) => (
              <MenuLink 
                key={item.path}
                {...item}
                active={location.pathname === item.path}
              />
            ))}
          </div>

          <div className="my-4 border-t border-indigo-600" />

          <div className="space-y-1">
            {menuItems.nodes.map((item) => (
              <MenuLink 
                key={item.path}
                {...item}
                active={location.pathname === item.path}
              />
            ))}
          </div>

          <div className="my-4 border-t border-indigo-600" />

          <div className="space-y-1">
            {menuItems.management.map((item) => (
              <MenuLink 
                key={item.path}
                {...item}
                active={location.pathname === item.path}
              />
            ))}
          </div>
        </nav>
      </aside>

      {/* Mobile overlay */}
      {sidebarOpen && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-50 z-20 lg:hidden"
          onClick={() => setSidebarOpen(false)}
        />
      )}

      {/* Main content wrapper */}
      <div className="flex-1 flex flex-col min-w-0 overflow-hidden">
        {/* Header */}
        <Header setSidebarOpen={setSidebarOpen} />

        {/* Main content area */}
        <main className="flex-1 overflow-y-auto bg-gray-100">
          <div className="container mx-auto px-6 py-8">
            {children}
          </div>
        </main>
      </div>
    </div>
  );
}

const MenuLink = ({ icon, text, path, active }) => (
  <Link
    to={path}
    className={`
      flex items-center px-3 py-2 rounded-lg transition-colors duration-200
      ${active 
        ? 'bg-indigo-800 bg-opacity-75 text-white' 
        : 'text-indigo-100 hover:bg-indigo-800 hover:bg-opacity-50'}
    `}
  >
    <span className="mr-3">{icon}</span>
    <span className="text-sm font-medium">{text}</span>
  </Link>
);