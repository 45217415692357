// src/config/networks.js
import waxLogo from '../assets/wax.webp';
import telosLogo from '../assets/telos.png';

export const networks = [
  {
    id: 'wax-mainnet',
    name: 'WAX',
    chainId: '1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4',
    rpcEndpoints: [
      {
        protocol: 'https',
        host: 'wax.greymass.com',
        port: 443,
      },
      {
        protocol: 'https',
        host: 'api.waxsweden.org',
        port: 443,
      }
    ],
    symbol: 'WAX',
    blockExplorerUrl: 'https://wax.chainscout.io',
    chainFamily: 'WAX',
    token: 'WAX',
    logo: waxLogo,
    subdomain: 'wax'
  },
  {
    id: 'wax-testnet',
    name: 'WAX Testnet',
    chainId: 'f16b1833c747c43682f4386fca9cbb327929334a762755ebec17f6f23c9b8a12',
    rpcEndpoints: [
      {
        protocol: 'https',
        host: 'testnet.waxsweden.org',
        port: 443,
      }
    ],
    symbol: 'WAX',
    blockExplorerUrl: 'https://wax-tesnet.chainscout.io',
    chainFamily: 'WAX',
    token: 'WAX',
    logo: waxLogo,
    subdomain: 'wax-testnet'
  },
  {
    id: 'telos-mainnet',
    name: 'TELOS',
    chainId: '4667b205c6838ef70ff7988f6e8257e8be0e1284a2f59699054a018f743b1d11',
    rpcEndpoints: [
      {
        protocol: 'https',
        host: 'mainnet.telos.net',
        port: 443,
      }
    ],
    symbol: 'TLOS',
    blockExplorerUrl: 'https://telos.chainscout.io',
    chainFamily: 'TELOS',
    token: 'TLOS',
    logo: telosLogo,
    subdomain: 'telos'
  },
  {
    id: 'telos-testnet',
    name: 'TELOS Testnet',
    chainId: '1eaa0824707c8c16bd25145493bf062aecddfeb56c736f6ba6397f3195f33c9f',
    rpcEndpoints: [
      {
        protocol: 'https',
        host: 'testnet.telos.net',
        port: 443,
      }
    ],
    symbol: 'TLOS',
    blockExplorerUrl: 'https://telos-tesnet.chainscout.io',
    chainFamily: 'TELOS',
    token: 'TLOS', 
    logo: telosLogo,
    subdomain: 'telos-testnet'
  }
];

// Helper functions
export const getNetworkById = (id) => {
  const network = networks.find(network => network.id === id);
  if (!network) {
    throw new Error(`No configuration found for network: ${id}`);
  }
  return network;
};

export const getDefaultNetwork = () => networks[0];

export const getMainnetNetworks = () => networks.filter(network => !network.id.includes('testnet'));

export const getTestnetNetworks = () => networks.filter(network => network.id.includes('testnet'));

export const getRpcEndpoint = (network) => {
  if (!network.rpcEndpoints || network.rpcEndpoints.length === 0) {
    throw new Error(`No RPC endpoints configured for network: ${network.id}`);
  }
  const endpoint = network.rpcEndpoints[0];
  return `${endpoint.protocol}://${endpoint.host}${endpoint.port ? `:${endpoint.port}` : ''}`;
};


// Mapping de nombres de BD a IDs de network
const networkMapping = {
  'WAX': 'wax-mainnet',
  'WAX_TESTNET': 'wax-testnet',
  'TELOS': 'telos-mainnet',
  'TELOS_TESTNET': 'telos-testnet'
};

// Nueva función para convertir nombre de BD a ID de network
export const convertDbChainToNetworkId = (dbChain) => {
  return networkMapping[dbChain] || dbChain;
};

// Nueva función para convertir ID de network a nombre de BD
export const convertNetworkIdToDbChain = (networkId) => {
  return Object.entries(networkMapping).find(([dbChain, id]) => id === networkId)?.[0] || networkId;
};


export default networks;