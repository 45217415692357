import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Activity, Radio, Server, History, Database, Box, Calendar as CalendarIcon } from 'lucide-react';
import { useNetwork } from '../context/networkContext';
import { DateRange, Popover, PopoverContent, PopoverTrigger } from '@radix-ui/react-popover';
import { DayPicker } from 'react-day-picker';
import { format, differenceInDays, addDays, startOfToday } from 'date-fns';
import { 
  ResponsiveContainer, 
  AreaChart, 
  Area, 
  XAxis, 
  YAxis, 
  Tooltip 
} from 'recharts';
import ApiService from '../services/api';
import 'react-day-picker/dist/style.css';
import { Link } from 'react-router-dom';
import { Clock } from 'lucide-react';

// Existing service configuration
const serviceConfig = {
  seed: {
    icon: <Radio size={32} />,
    name: 'Seed'
  },
  api: {
    icon: <Server size={32} />,
    name: 'API'
  },
  v1: {
    icon: <History size={32} />,
    name: 'V1'
  },
  v2: {
    icon: <Database size={32} />,
    name: 'V2'
  },
  atomic: {
    icon: <Box size={32} />,
    name: 'Atomic'
  }
};

// Utility function for date formatting
const formatDate = (timestamp) => {
  const date = new Date(timestamp);
  return date.toLocaleDateString('en-US', {
    day: '2-digit',
    month: 'short'
  });
};

// Tooltip component for graphs
const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-white p-3 shadow-lg rounded-lg border border-gray-200">
        <p className="text-sm text-gray-600">{formatDate(label)}</p>
        <p className="text-sm font-medium">
          Availability: {payload[0].value !== undefined ? payload[0].value.toFixed(2) : 'N/A'}%
        </p>
      </div>
    );
  }
  return null;
};

// Button component for timeframe selection
const TimeframeButton = ({ active, children, onClick }) => (
  <button
    onClick={onClick}
    className={`px-3 py-1 md:px-4 md:py-2 rounded-lg font-medium transition-colors whitespace-nowrap ${
      active 
        ? 'bg-indigo-600 text-white' 
        : 'bg-white text-gray-600 hover:bg-gray-50'
    }`}
  >
    {children}
  </button>
);

// New date range picker component
const DateRangePickerButton = ({ selected, onSelect, className }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [dateRange, setDateRange] = useState(selected);
  const today = startOfToday();
  const sixtyDaysAgo = addDays(today, -60);

  const handleSelect = (range) => {
    setDateRange(range);
  };

  const handleApply = () => {
    if (dateRange?.from && dateRange?.to) {
      onSelect(dateRange);
      setIsOpen(false);
    }
  };

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger asChild>
        <button
          className={`flex items-center gap-2 px-3 py-1 md:px-4 md:py-2 rounded-lg font-medium transition-colors whitespace-nowrap
            ${selected ? 'bg-indigo-600 text-white' : 'bg-white text-gray-600 hover:bg-gray-50'}
            ${className}`}
        >
          <CalendarIcon size={16} />
          <span>
            {selected?.from ? (
              `${format(selected.from, 'MMM d')} - ${format(selected.to, 'MMM d')}`
            ) : (
              'Custom Range'
            )}
          </span>
        </button>
      </PopoverTrigger>

      <PopoverContent className="w-auto p-0 z-50" align="end" side="bottom">
        <div className="p-4 bg-white rounded-lg shadow-lg border border-gray-200 relative z-50">
          <div className="mb-4">
            <h3 className="font-medium text-gray-900 mb-1">Select date range</h3>
            <p className="text-sm text-gray-500">Maximum 60 days from today</p>
          </div>

          <div className="bg-white">
            <DayPicker
              mode="range"
              defaultMonth={dateRange?.from}
              selected={dateRange}
              onSelect={handleSelect}
              numberOfMonths={2}
              disabled={[
                { before: sixtyDaysAgo },
                { after: today }
              ]}
              className="bg-white"
            />
          </div>

          <div className="flex items-center justify-between mt-4 border-t pt-4 bg-white">
            <span className="text-sm text-gray-600">
              {dateRange?.from && dateRange?.to ? (
                `${differenceInDays(dateRange.to, dateRange.from) + 1} days selected`
              ) : 'No range selected'}
            </span>
            <div className="space-x-2">
              <button
                onClick={() => {
                  setDateRange(null);
                  setIsOpen(false);
                }}
                className="px-3 py-1 text-sm text-gray-600 hover:text-gray-900"
              >
                Cancel
              </button>
              <button
                onClick={handleApply}
                disabled={!dateRange?.from || !dateRange?.to}
                className="px-3 py-1 text-sm bg-indigo-600 text-white rounded hover:bg-indigo-700 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                Apply
              </button>
            </div>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
};

// Endpoint card component for displaying individual endpoint data
const EndpointCard = ({ endpoint, timeframe }) => {
  const statusColor = endpoint.status ? 'text-green-600' : 'text-red-600';

  return (
    <div className="bg-white rounded-lg p-6 shadow-sm">
      <div className="flex items-center justify-between mb-4">
        <div className="flex-1">
          <h3 className="text-lg font-medium text-gray-900 flex items-center gap-2">
            <span>{endpoint.name}</span>
            <span className={`text-sm ${statusColor}`}>
              ({endpoint.status ? 'Online' : 'Offline'})
            </span>
          </h3>
          <p className="text-gray-500 text-sm mt-1">
            {endpoint.api_endpoint || endpoint.p2p_endpoint || endpoint.ssl_endpoint}
          </p>
        </div>
        <div className="text-right">
          <div className="text-sm font-medium text-gray-900">
            Average {timeframe}:
          </div>
          <div className={`text-lg font-bold ${
            endpoint.averages[timeframe] !== undefined ?
              (endpoint.averages[timeframe] >= 95 ? 'text-green-600' : 
              endpoint.averages[timeframe] >= 90 ? 'text-yellow-600' : 'text-red-600')
              : 'text-gray-500'
          }`}>
            {endpoint.averages[timeframe] !== undefined ? endpoint.averages[timeframe].toFixed(1) : 'N/A'}%
          </div>
        </div>
      </div>

      <div className="h-64 mt-4">
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart data={endpoint.graphData} margin={{ top: 5, right: 30, left: 10, bottom: 5 }}>
            <XAxis dataKey="timestamp" tickFormatter={formatDate} stroke="#94a3b8" />
            <YAxis domain={[0, 100]} tickFormatter={(value) => `${value}%`} stroke="#94a3b8" />
            <Tooltip content={<CustomTooltip />} />
            <Area
              type="monotone"
              dataKey="value"
              fill="#6366f1"
              fillOpacity={0.3}
              stroke="none"
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>

      <div className="mt-4 grid grid-cols-3 gap-4 text-sm">
        {['7d', '30d', '45d'].map((key) => (
          <div key={key} className="text-center">
            <div className="text-gray-500">{key === '7d' ? '7 days' : key === '30d' ? '30 days' : '45 days'}</div>
            <div className={`font-bold ${
              endpoint.averages[key] !== undefined ?
                (endpoint.averages[key] >= 95 ? 'text-green-600' : 
                endpoint.averages[key] >= 90 ? 'text-yellow-600' : 'text-red-600')
                : 'text-gray-500'
            }`}>
              {endpoint.averages[key] !== undefined ? endpoint.averages[key].toFixed(1) : 'N/A'}%
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

// Main component
const ProducerDetails = () => {
  const { blockchain, producer } = useParams();
  const { currentNetwork } = useNetwork();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [producerData, setProducerData] = useState(null);
  const [timeframe, setTimeframe] = useState('7d');
  const [dateRange, setDateRange] = useState(null);

  useEffect(() => {
    const fetchProducerDetails = async () => {
      try {
          setLoading(true);
          let params = {};
          
          if (dateRange?.from && dateRange?.to) {
              params = {
                  from: format(dateRange.from, 'yyyy-MM-dd'),
                  to: format(dateRange.to, 'yyyy-MM-dd')
              };
          } else {
              params = {
                  days: timeframe.replace('d', '')
              };
          }
          
          const data = await ApiService.getProducerDetails(blockchain, producer, params);
          console.log('Producer Details Data:', data); // Añadido para depuración
          setProducerData(data);
      } catch (err) {
          setError(err.message);
      } finally {
          setLoading(false);
      }
    };

    fetchProducerDetails();
  }, [blockchain, producer, timeframe, dateRange]);

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <Activity className="animate-spin h-8 w-8 text-indigo-500" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-red-500 text-center p-4">
        Error loading producer details: {error}
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {/* Header with producer info */}
      <div className="flex items-center justify-between bg-white p-6 rounded-lg shadow-sm flex-wrap gap-4 md:gap-6">
        <div className="flex items-center gap-4">
        <div className="w-16 h-16 overflow-hidden rounded-lg flex items-center justify-center bg-indigo-50">
  {producerData?.logo ? (
    <img 
      src={producerData.logo} 
      alt={producer}
      className="w-full h-full object-contain"
    />
  ) : (
    <span className="text-indigo-500 text-2xl font-semibold">PR</span>
  )}
</div>
          
          <div>
          <div className="flex items-center gap-2">
    <h1 className="text-2xl font-bold text-gray-900">{producer}</h1>
    <Link 
      to={`/producer/${blockchain}/${producer}/validations`}
      className="inline-flex items-center gap-1 text-indigo-600 hover:text-indigo-700 text-sm font-medium"
    >
      <Clock size={16} />
      View Validations History
    </Link>
  </div>
            <p className="text-gray-500">{blockchain}</p>
          </div>
        </div>

        {/* Date selection controls */}
        <div className="flex items-center gap-2 md:gap-3 flex-wrap">
          <TimeframeButton 
            active={timeframe === '7d' && !dateRange} 
            onClick={() => {
              setTimeframe('7d');
              setDateRange(null);
            }}
          >
            7 days
          </TimeframeButton>
          <TimeframeButton 
            active={timeframe === '30d' && !dateRange}
            onClick={() => {
              setTimeframe('30d');
              setDateRange(null);
            }}
          >
            30 days
          </TimeframeButton>
          <TimeframeButton 
            active={timeframe === '45d' && !dateRange}
            onClick={() => {
              setTimeframe('45d');
              setDateRange(null);
            }}
          >
            45 days
          </TimeframeButton>

          <DateRangePickerButton
            selected={dateRange}
            onSelect={(range) => {
              setDateRange(range);
              setTimeframe('custom');
            }}
            className="whitespace-nowrap"
          />
        </div>
      </div>

      {/* Endpoints grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {producerData?.endpoints?.map(endpoint => (
          <EndpointCard 
            key={endpoint.idNode}
            endpoint={endpoint}
            timeframe={timeframe}
          />
        ))}
      </div>
    </div>
  );
};

export default ProducerDetails;
