import React, { useState, useEffect } from 'react';
import { 
  Activity,
  Radio, 
  Server, 
  History, 
  Database, 
  Box,
  ChevronDown,
  ChevronRight,
  Search
} from 'lucide-react';
import { useNetwork } from '../context/networkContext';
import { useAuth } from '../hooks/useAuth';
import ApiService from '../services/api';
import { convertDbChainToNetworkId } from '../config/networks';  

// Service configuration
const serviceConfig = {
  seed: {
    icon: <Radio size={24} />,
    name: 'Seed'
  },
  api: {
    icon: <Server size={24} />,
    name: 'API'
  },
  v1: {
    icon: <History size={24} />,
    name: 'V1'
  },
  v2: {
    icon: <Database size={24} />,
    name: 'V2'
  },
  atomic: {
    icon: <Box size={24} />,
    name: 'Atomic'
  }
};

// Filter button component
const FilterButton = ({ active, children, onClick }) => (
  <button
    onClick={onClick}
    className={`px-4 py-2 rounded-lg font-medium transition-colors ${
      active 
        ? 'bg-indigo-600 text-white' 
        : 'bg-white text-gray-600 hover:bg-gray-50'
    }`}
  >
    {children}
  </button>
);

// Search input component
const SearchInput = ({ value, onChange }) => (
  <div className="relative">
    <div className="absolute inset-y-0 left-3 flex items-center pointer-events-none">
      <Search size={20} className="text-gray-400" />
    </div>
    <input
      type="text"
      placeholder="Search producer..."
      className="w-64 pl-10 pr-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500"
      value={value}
      onChange={(e) => onChange(e.target.value)}
    />
  </div>
);

// Service Toggle component
const ServiceToggle = ({ type, producer, isSubscribed, onToggle, isLoading }) => {
  return (
    <div className="flex items-center justify-between p-2 hover:bg-gray-50 rounded">
      <div className="flex items-center gap-3">
        <div className={`${isSubscribed ? 'text-indigo-600' : 'text-gray-400'}`}>
          {serviceConfig[type].icon}
        </div>
        <span className="font-medium text-gray-900">{serviceConfig[type].name}</span>
      </div>
      <div className="flex items-center gap-2">
        {isLoading && (
          <Activity className="animate-spin h-4 w-4 text-indigo-600" />
        )}
        <label className="relative inline-flex items-center cursor-pointer">
          <input
            type="checkbox"
            className="sr-only peer"
            checked={isSubscribed}
            onChange={() => onToggle(type)}
            disabled={isLoading}
          />
          <div className={`w-11 h-6 bg-gray-200 rounded-full peer 
            peer-checked:after:translate-x-full peer-checked:after:border-white 
            after:content-[''] after:absolute after:top-0.5 after:left-[2px] 
            after:bg-white after:border-gray-300 after:border after:rounded-full 
            after:h-5 after:w-5 after:transition-all peer-checked:bg-indigo-600
            ${isLoading ? 'opacity-50' : ''}`}>
          </div>
        </label>
      </div>
    </div>
  );
};

// Helper function to determine service type from a node
const getServiceType = (node) => {
  if (node.idFeature === -1) return 'seed';
  if (node.idFeature === 1) return 'api';
  if (node.idFeature === 3) return 'v1';
  if (node.idFeature === 4) return 'v2';
  if (node.idFeature === 9) return 'atomic';
  return null;
};

// Producer Row component
const ProducerRow = ({ producer, userSubscriptions, onToggleSubscription }) => {
  const { currentNetwork } = useNetwork();
  const network = currentNetwork?.id || 'wax-mainnet';
  const [isExpanded, setIsExpanded] = useState(false);
  const [loadingServices, setLoadingServices] = useState({});
  const countryCode = producer.country?.toLowerCase() || 'un';

  const handleServiceToggle = async (serviceType) => {
    try {
      setLoadingServices(prev => ({ ...prev, [serviceType]: true }));
      await onToggleSubscription(producer, serviceType);
    } finally {
      setLoadingServices(prev => ({ ...prev, [serviceType]: false }));
    }
  };

  const isServiceSubscribed = (serviceType) => {
    return userSubscriptions.some(sub => 
      sub.producer === producer.name && 
      getServiceType(sub) === serviceType &&
      convertDbChainToNetworkId(sub.blockchain) === network
    );
  };

  return (
    <div className="bg-white rounded-lg shadow-sm hover:shadow-md transition-shadow">
      <div 
        className="p-4 cursor-pointer"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <div className="grid grid-cols-12 items-center">
          <div className="col-span-11 flex items-center gap-4">
            <div className="text-gray-400">
              {isExpanded ? <ChevronDown size={20} /> : <ChevronRight size={20} />}
            </div>

            <span className="text-gray-500 font-medium w-8">#{producer.position}</span>

            <div className="w-10 h-10 overflow-hidden rounded-lg flex items-center justify-center bg-indigo-50">
              {producer.logo ? (
                <img 
                  src={producer.logo} 
                  alt={producer.name}
                  className="w-full h-full object-contain"
                />
              ) : (
                <span className="text-indigo-500 font-semibold">PR</span>
              )}
            </div>

            <div className="flex items-center gap-2">
              <span className="font-medium text-gray-900">{producer.name}</span>
              {producer.isTop21 && (
                <span className="px-2 py-0.5 text-xs font-medium text-blue-600 bg-blue-50 rounded-full">
                  Top 21
                </span>
              )}
              {countryCode !== 'un' && (
                <img 
                  src={`https://flagcdn.com/20x15/${countryCode}.png`}
                  alt={producer.country}
                  className="h-4"
                />
              )}
            </div>
          </div>

          <div className="col-span-1 flex justify-end gap-2">
            {Object.keys(serviceConfig).map(type => 
              producer.nodes && producer.nodes[type] ? (
                <div 
                  key={type}
                  className={`w-2 h-2 rounded-full ${
                    isServiceSubscribed(type) ? 'bg-indigo-600' : 'bg-gray-300'
                  }`}
                />
              ) : null
            )}
          </div>
        </div>
      </div>

      {isExpanded && (
        <div className="px-4 pb-4 border-t">
          <div className="space-y-1 mt-2">
            {Object.keys(serviceConfig).map(type => 
              producer.nodes && producer.nodes[type] ? (
                <ServiceToggle
                  key={type}
                  type={type}
                  producer={producer}
                  isSubscribed={isServiceSubscribed(type)}
                  onToggle={handleServiceToggle}
                  isLoading={loadingServices[type]}
                />
              ) : null
            )}
          </div>
        </div>
      )}
    </div>
  );
};

// Main Subscriptions component
const Subscriptions = () => {
  const { currentNetwork } = useNetwork();
  const { user } = useAuth();
  const [filter, setFilter] = useState('all');
  const [searchTerm, setSearchTerm] = useState('');
  const [producers, setProducers] = useState([]);
  const [userSubscriptions, setUserSubscriptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const network = currentNetwork?.id || 'wax-mainnet';

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const [producersData, subscriptionsData] = await Promise.all([
          ApiService.getBPData(network),
          ApiService.getUserSubscriptions()
        ]);

        setProducers(producersData.producers);
        setUserSubscriptions(subscriptionsData.subscriptions);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (user) {
      fetchData();
    }
  }, [network, user]);

  const handleToggleSubscription = async (producer, serviceType) => {
    try {
      const nodeIds = producer.nodes[serviceType]?.ids || [];

      if (nodeIds.length === 0) {
        return;
      }

      const isCurrentlySubscribed = userSubscriptions.some(sub => 
        sub.producer === producer.name && 
        getServiceType(sub) === serviceType &&
        convertDbChainToNetworkId(sub.blockchain) === network
      );

      if (isCurrentlySubscribed) {
        await Promise.all(nodeIds.map(nodeId => ApiService.unsubscribe(nodeId)));
        
        setUserSubscriptions(prev => 
          prev.filter(sub => 
            !(sub.producer === producer.name && 
              getServiceType(sub) === serviceType &&
              convertDbChainToNetworkId(sub.blockchain) === network)
          )
        );
      } else {
        await Promise.all(nodeIds.map(nodeId => ApiService.subscribe(nodeId)));
        const subscriptionsData = await ApiService.getUserSubscriptions();
        setUserSubscriptions(subscriptionsData.subscriptions);
      }
    } catch (err) {
      setError('Error toggling subscription');
    }
  };

  const getCurrentNetworkSubscriptions = () => {
    return userSubscriptions.filter(sub => 
      convertDbChainToNetworkId(sub.blockchain) === network
    );
  };

  const filteredProducers = producers.filter(producer => {
    const matchesSearch = producer.name.toLowerCase().includes(searchTerm.toLowerCase());
    if (!matchesSearch) return false;

    const currentNetworkSubs = getCurrentNetworkSubscriptions();

    switch (filter) {
      case 'subscribed':
        return currentNetworkSubs.some(sub => sub.producer === producer.name);
      case 'unsubscribed':
        return !currentNetworkSubs.some(sub => sub.producer === producer.name);
      default:
        return true;
    }
  });

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <Activity className="animate-spin h-8 w-8 text-indigo-600" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-red-500 text-center p-4">
        Error loading producers: {error}
      </div>
    );
  }

  if (!user) {
    return (
      <div className="text-center p-8">
        <h2 className="text-2xl font-bold text-gray-900 mb-4">
          Please log in to manage your subscriptions
        </h2>
        <p className="text-gray-600">
          You need to be logged in to view and manage your node subscriptions.
        </p>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="bg-white rounded-lg p-4 shadow-sm">
        <h2 className="text-lg font-medium text-gray-900 mb-2">
          Subscribe to specific node types from each block producer to receive status notifications
        </h2>
        <p className="text-sm text-gray-600">
          Click on a producer row to expand and manage your subscriptions
        </p>
      </div>
      
      <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between gap-4">
        <div className="flex flex-col sm:flex-row items-start sm:items-center gap-3 w-full">
          <FilterButton 
            active={filter === 'all'} 
            onClick={() => setFilter('all')}
          >
            All Nodes
          </FilterButton>
          <FilterButton 
            active={filter === 'subscribed'} 
            onClick={() => setFilter('subscribed')}
          >
            My Subscriptions
          </FilterButton>
          <FilterButton 
            active={filter === 'unsubscribed'} 
            onClick={() => setFilter('unsubscribed')}
          >
            Not Subscribed
          </FilterButton>
        </div>

        <div className="w-full lg:w-auto mt-2 lg:mt-0">
          <SearchInput 
            value={searchTerm}
            onChange={setSearchTerm}
          />
        </div>
      </div>

      <div className="space-y-3">
        {filteredProducers.map(producer => (
          <ProducerRow 
            key={producer.name}
            producer={producer}
            userSubscriptions={userSubscriptions}
            onToggleSubscription={handleToggleSubscription}
          />
        ))}
      </div>

      {filteredProducers.length === 0 && (
        <div className="text-center text-gray-500 py-8">
          No producers found matching your criteria
        </div>
      )}
    </div>
  );
};

export default Subscriptions;