// services/api.js

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001/api';

class ApiService {
  static async getBPData(blockchain) {
    try {
      const response = await fetch(`${API_URL}/${blockchain}/bp_data`);
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching BP data:', error);
      throw new Error('Failed to fetch BP data');
    }
  }

  static async getBPStatistics(blockchain) {
    try {
      const response = await fetch(`${API_URL}/${blockchain}/bp_statistics`);
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching BP statistics:', error);
      throw new Error('Failed to fetch BP statistics');
    }
  }

  // New subscription methods
  static async getUserSubscriptions() {
    const token = localStorage.getItem('token');
    try {
      const response = await fetch(`${API_URL}/subscriptions/user`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      const data = await response.json();

      console.log('data:', data);

      if (!response.ok) {
        throw new Error(data.error || 'Failed to fetch subscriptions');
      }
      return data;
    } catch (error) {
      console.error('Error fetching user subscriptions:', error);
      throw error;
    }
  }

  static async subscribe(nodeId) {
    const token = localStorage.getItem('token');
    try {
      const response = await fetch(`${API_URL}/subscriptions/node/${nodeId}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.error || 'Failed to subscribe');
      }
      return data;
    } catch (error) {
      console.error('Error subscribing to node:', error);
      throw error;
    }
  }

  static async unsubscribe(nodeId) {
    const token = localStorage.getItem('token');
    try {
      const response = await fetch(`${API_URL}/subscriptions/node/${nodeId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.error || 'Failed to unsubscribe');
      }
      return data;
    } catch (error) {
      console.error('Error unsubscribing from node:', error);
      throw error;
    }
  }

  static async checkSubscription(nodeId) {
    const token = localStorage.getItem('token');
    try {
      const response = await fetch(`${API_URL}/subscriptions/node/${nodeId}/check`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.error || 'Failed to check subscription');
      }
      return data;
    } catch (error) {
      console.error('Error checking subscription:', error);
      throw error;
    }
  }
  
  static async getProducerValidations(blockchain, producer, params = {}) {
    try {
        const queryParams = new URLSearchParams();
        
        if (params.from) {
            queryParams.append('from', params.from);
        }
        
        if (params.to) {
            queryParams.append('to', params.to);
        }

        const response = await fetch(
            `${API_URL}/${blockchain}/producer/${producer}/validations${
                queryParams.toString() ? `?${queryParams}` : ''
            }`
        );

        if (!response.ok) {
            const data = await response.json();
            throw new Error(data.error || 'Failed to fetch validation history');
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching producer validations:', error);
        throw new Error('Failed to fetch validation history');
    }
}

  static async getProducerDetails(blockchain, producer, params = {}) {
    try {
        let queryParams = new URLSearchParams();
        
        // Si tenemos rango de fechas
        if (params.from && params.to) {
            queryParams.append('from', params.from);
            queryParams.append('to', params.to);
        }
        // Si tenemos días o usamos el valor por defecto
        else if (params.days) {
            queryParams.append('days', params.days);
        }

        const response = await fetch(`${API_URL}/${blockchain}/producer/${producer}/details?${queryParams}`);
        const data = await response.json();
        
        if (!response.ok) {
            throw new Error(data.error || 'Failed to fetch producer details');
        }
        
        return data;
    } catch (error) {
        console.error('Error fetching producer details:', error);
        throw new Error('Failed to fetch producer details');
    }
}


}

export default ApiService;