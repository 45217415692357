import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';
import ApiService from '../services/api';
import { Activity, ChevronDown, ChevronRight, Radio, Server, History, Database, Box } from 'lucide-react';
import * as Slider from '@radix-ui/react-slider';

const FeatureType = ({ idFeature }) => {
  const featureMap = {
    '-1': { name: 'Seed', icon: <Radio size={16} /> },
    '1': { name: 'API', icon: <Server size={16} /> },
    '3': { name: 'V1', icon: <History size={16} /> },
    '4': { name: 'V2', icon: <Database size={16} /> },
    '9': { name: 'Atomic', icon: <Box size={16} /> }
  };

  const feature = featureMap[idFeature] || { name: `Feature ${idFeature}`, icon: null };

  return (
    <span className="px-2 py-1 bg-indigo-100 text-indigo-700 rounded-full text-xs font-medium inline-flex items-center gap-1">
      {feature.icon}
      {feature.name}
    </span>
  );
};

const StatusIcon = ({ status }) => (
  <div className={`w-3 h-3 rounded-full ${status ? 'bg-green-500' : 'bg-red-500'}`} />
);

const TableRow = ({ validation }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const formattedDate = format(new Date(parseInt(validation.date)), 'MMM dd, yyyy HH:mm:ss');
  const formattedMessage = validation.message?.replace(/<ok>/g, '\n✓ ').replace(/<ko>/g, '\n✗ ');
  
  return (
    <>
      <tr className="border-b border-gray-200 hover:bg-gray-50">
        <td className="px-4 py-2">
          {validation.message && (
            <button onClick={() => setIsExpanded(!isExpanded)} className="p-1 hover:bg-gray-200 rounded">
              {isExpanded ? <ChevronDown size={16} /> : <ChevronRight size={16} />}
            </button>
          )}
        </td>
        <td className="px-4 py-2 text-sm text-gray-900">{formattedDate}</td>
        <td className="px-4 py-2 text-sm text-gray-900">
          <div className="max-w-md truncate" title={validation.endpoint}>{validation.endpoint}</div>
        </td>
        <td className="px-4 py-2"><FeatureType idFeature={validation.idFeature} /></td>
        <td className="px-4 py-2">
          <div className="flex items-center gap-2">
            <StatusIcon status={validation.status} />
            <span className="text-sm text-gray-600">{validation.status ? 'Success' : 'Failure'}</span>
          </div>
        </td>
      </tr>
      {isExpanded && validation.message && (
        <tr className="bg-gray-50">
          <td colSpan="5" className="px-12 py-3">
            <pre className="text-sm text-gray-600 whitespace-pre-wrap font-mono">{formattedMessage}</pre>
          </td>
        </tr>
      )}
    </>
  );
};

const TimeframeButton = ({ active, children, onClick, disabled }) => (
  <button
    onClick={onClick}
    disabled={disabled}
    className={`px-4 py-2 rounded-lg font-medium transition-colors ${
      active ? 'bg-indigo-600 text-white' : 'bg-white text-gray-600 hover:bg-gray-50'
    } ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`}
  >
    {children}
  </button>
);

const DateSliderControl = ({ value, maxDays, onChange, onCommit, isLoading }) => (
  <Slider.Root
    className="relative flex items-center select-none touch-none w-full h-5"
    value={[value]}
    max={maxDays}
    step={1}
    onValueChange={onChange}
    onValueCommit={onCommit}
    disabled={isLoading}
    aria-label="Date"
  >
    <Slider.Track className="bg-gray-200 relative grow rounded-full h-[3px]">
      <Slider.Range className="absolute bg-indigo-600 rounded-full h-full" />
    </Slider.Track>
    <Slider.Thumb className={`block w-5 h-5 bg-white shadow-lg border-2 border-indigo-600 rounded-full hover:bg-indigo-50 focus:outline-none focus:ring-2 focus:ring-indigo-400 focus:ring-offset-2 ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`} />
  </Slider.Root>
);

const ProducerValidations = () => {
  const { blockchain, producer } = useParams();
  
  const [timeState, setTimeState] = useState({
    timeframe: '7d',
    sliderValue: 0,
    selectedDate: new Date()
  });
  
  const [validationState, setValidationState] = useState({
    loading: true,
    error: null,
    history: [],
    producerData: null
  });
  
  const [selectedFeature, setSelectedFeature] = useState(null);
  const [previewDate, setPreviewDate] = useState(new Date());
  
  const getMaxDays = useCallback(() => {
    const timeframes = { '30d': 30, '45d': 45, '7d': 7 };
    return timeframes[timeState.timeframe] || 7;
  }, [timeState.timeframe]);

  const fetchValidationHistory = useCallback(async (targetDate) => {
    setValidationState(prev => ({ ...prev, loading: true }));
    try {
      const formattedDate = format(targetDate, 'yyyy-MM-dd');
      const data = await ApiService.getProducerValidations(blockchain, producer, {
        from: formattedDate,
        to: formattedDate
      });
      const sortedHistory = data.history.sort((a, b) => parseInt(b.date) - parseInt(a.date));
      setValidationState(prev => ({
        ...prev,
        loading: false,
        error: null,
        history: sortedHistory,
        producerData: data
      }));
    } catch (err) {
      setValidationState(prev => ({
        ...prev,
        loading: false,
        error: err.message
      }));
    }
  }, [blockchain, producer]);

  const calculateNewDate = useCallback((sliderValue) => {
    const maxDays = getMaxDays();
    const today = new Date();
    const targetDate = new Date(today);
    targetDate.setDate(today.getDate() - (maxDays - sliderValue));
    return targetDate;
  }, [getMaxDays]);

  const handleTimeframeChange = useCallback((newTimeframe) => {
    setTimeState(prev => ({
      ...prev,
      timeframe: newTimeframe,
      sliderValue: 0,
      selectedDate: new Date()
    }));
    setPreviewDate(new Date());
    fetchValidationHistory(new Date());
  }, [fetchValidationHistory]);

  const handleSliderMove = useCallback(([value]) => {
    const newDate = calculateNewDate(value);
    setPreviewDate(newDate);
    setTimeState(prev => ({
      ...prev,
      sliderValue: value,
    }));
  }, [calculateNewDate]);

  const handleSliderCommit = useCallback(([value]) => {
    const newDate = calculateNewDate(value);
    setTimeState(prev => ({
      ...prev,
      sliderValue: value,
      selectedDate: newDate
    }));
    fetchValidationHistory(newDate);
  }, [calculateNewDate, fetchValidationHistory]);

  useEffect(() => {
    fetchValidationHistory(timeState.selectedDate);
  }, [fetchValidationHistory, timeState.selectedDate]);

  const filteredHistory = selectedFeature !== null 
    ? validationState.history.filter(item => item.idFeature.toString() === selectedFeature)
    : validationState.history;

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between bg-white p-6 rounded-lg shadow-sm flex-wrap gap-4 md:gap-6">
        <div className="flex items-center gap-4">
          <div className="w-16 h-16 overflow-hidden rounded-lg flex items-center justify-center bg-indigo-50">
            {validationState.producerData?.logo ? (
              <img src={validationState.producerData.logo} alt={producer} className="w-full h-full object-contain" />
            ) : (
              <span className="text-indigo-500 text-2xl font-semibold">PR</span>
            )}
          </div>
          <div>
            <h1 className="text-2xl font-bold text-gray-900">{producer}</h1>
            <p className="text-gray-500">{blockchain}</p>
          </div>
        </div>
        <div className="flex items-center gap-2 md:gap-3 flex-wrap">
          {['7d', '30d', '45d'].map((tf) => (
            <TimeframeButton 
              key={tf}
              active={timeState.timeframe === tf} 
              onClick={() => handleTimeframeChange(tf)}
              disabled={validationState.loading}
            >
              {tf.replace('d', ' days')}
            </TimeframeButton>
          ))}
        </div>
      </div>

      <div className="bg-white shadow-sm rounded-lg p-6">
        <div className="mb-2 flex justify-between text-sm text-gray-600">
          <span>{format(new Date().setDate(new Date().getDate() - getMaxDays()), 'MMM d, yyyy')}</span>
          <span>{format(new Date(), 'MMM d, yyyy')}</span>
        </div>
        <DateSliderControl
          value={timeState.sliderValue}
          maxDays={getMaxDays()}
          onChange={handleSliderMove}
          onCommit={handleSliderCommit}
          isLoading={validationState.loading}
        />
        <div className="mt-2 text-center">
          <span className="text-sm font-medium text-indigo-600">
            {format(previewDate, 'MMMM d, yyyy')}
          </span>
          {validationState.loading && (
            <span className="ml-2 text-sm text-gray-500">(loading...)</span>
          )}
        </div>
      </div>

      <div className="sticky top-0 z-10 bg-white shadow-sm p-4 rounded-lg">
        <div className="flex flex-wrap gap-2">
          {['-1', '1', '3', '4', '9'].map((featureId) => (
            <button
              key={featureId}
              onClick={() => setSelectedFeature(featureId === selectedFeature ? null : featureId)}
              className={`px-4 py-2 rounded-lg font-medium transition-colors ${
                selectedFeature === featureId
                  ? 'bg-indigo-600 text-white'
                  : 'bg-white text-gray-600 hover:bg-gray-50'
              }`}
              disabled={validationState.loading}
            >
              {featureId === '-1' ? 'Seed' : 
               featureId === '1' ? 'API' : 
               featureId === '3' ? 'V1' : 
               featureId === '4' ? 'V2' : 'Atomic'}
            </button>
          ))}
          <button
            onClick={() => setSelectedFeature(null)}
            className={`px-4 py-2 rounded-lg font-medium transition-colors ${
              selectedFeature === null
                ? 'bg-indigo-600 text-white'
                : 'bg-white text-gray-600 hover:bg-gray-50'
            }`}
            disabled={validationState.loading}
          >
            All
          </button>
        </div>
      </div>

      <div className="bg-white shadow-sm rounded-lg overflow-hidden">
        <div className="overflow-x-auto">
          <table className="w-full">
            <thead className="bg-gray-50">
              <tr>
                <th className="w-8 px-4 py-3" />
                <th className="text-left text-xs font-medium text-gray-500 uppercase tracking-wider px-4 py-3">Time</th>
                <th className="text-left text-xs font-medium text-gray-500 uppercase tracking-wider px-4 py-3">Endpoint</th>
                <th className="text-left text-xs font-medium text-gray-500 uppercase tracking-wider px-4 py-3">Type</th>
                <th className="text-left text-xs font-medium text-gray-500 uppercase tracking-wider px-4 py-3">Status</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {validationState.loading ? (
                <tr>
                  <td colSpan="5" className="px-4 py-8 text-center">
                    <Activity className="animate-spin h-8 w-8 text-indigo-500 mx-auto" />
                  </td>
                </tr>
              ) : validationState.error ? (
                <tr>
                  <td colSpan="5" className="px-4 py-8 text-center text-red-500">
                    Error loading validation history: {validationState.error}
                  </td>
                </tr>
              ) : filteredHistory.length === 0 ? (
                <tr>
                  <td colSpan="5" className="px-4 py-8 text-center text-gray-500">
                    No validation history found for the selected date
                  </td>
                </tr>
              ) : (
                filteredHistory.map((validation, index) => (
                  <TableRow key={`${validation.date}-${index}`} validation={validation} />
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ProducerValidations;