import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ProtectedRoute } from './routes';
import DashboardLayout from './components/DashboardLayout';
import Dashboard from './pages/Dashboard';
import Nodes from './pages/Nodes';
import favicon from './assets/favicon.ico';
import Statistics from './pages/Statistics';
import UserDashboard from './pages/UserDashboard';
import ProducerDetails from './pages/ProducerDetails';
import { NetworkProvider } from './context/networkContext';
import { AuthProvider } from './context/AuthContext';
import { LoginModalProvider } from './context/LoginModalContext';
import { LoginModal } from './components/LoginModal';
import { LoginForm } from './components/auth/LoginForm';
import { RegisterForm } from './components/auth/RegisterForm';
import { PasswordReset } from './components/auth/PasswordReset';
import Subscriptions from './pages/Subscriptions';
import { 
  SeedNodes, 
  ApiNodes, 
  V1Nodes, 
  V2Nodes, 
  AtomicNodes 
} from './pages/node-types';
import ProducerValidations from './pages/ProducerValidations';

function App() {
  useEffect(() => {
    const existingFavicon = document.querySelector("link[rel='icon']");
    
    if (existingFavicon) {
      existingFavicon.href = favicon;
    } else {
      const newFavicon = document.createElement('link');
      newFavicon.rel = 'icon';
      newFavicon.href = favicon;
      document.head.appendChild(newFavicon);
    }
  }, []);
  return (
    <AuthProvider>
      <NetworkProvider>
        <LoginModalProvider>
          <Router>
            <LoginModal />
            <Routes>
              {/* Auth routes - without DashboardLayout */}
              <Route path="/login" element={<LoginForm />} />
              <Route path="/register" element={<RegisterForm />} />
              <Route path="/forgot-password" element={<PasswordReset />} />
              
              {/* Public Dashboard routes */}
              <Route path="/" element={
                <DashboardLayout>
                  <Dashboard />
                </DashboardLayout>
              } />

              <Route path="/reset-password/:token" element={
                <DashboardLayout>
                  <PasswordReset />
                </DashboardLayout>
              } />

              {/* Node type routes */}
              <Route path="/seed" element={
                <DashboardLayout>
                  <SeedNodes />
                </DashboardLayout>
              } />
              
              <Route path="/api" element={
                <DashboardLayout>
                  <ApiNodes />
                </DashboardLayout>
              } />
              
              <Route path="/v1" element={
                <DashboardLayout>
                  <V1Nodes />
                </DashboardLayout>
              } />
              
              <Route path="/v2" element={
                <DashboardLayout>
                  <V2Nodes />
                </DashboardLayout>
              } />
              
              <Route path="/atomic" element={
                <DashboardLayout>
                  <AtomicNodes />
                </DashboardLayout>
              } />

              {/* Existing routes */}
              <Route path="/nodes" element={
                <DashboardLayout>
                  <Nodes />
                </DashboardLayout>
              } />

              <Route path="/statistics" element={
                <DashboardLayout>
                  <Statistics />
                </DashboardLayout>
              } />

              <Route path="/producer/:blockchain/:producer/details" element={
                <DashboardLayout>
                  <ProducerDetails />
                </DashboardLayout>
              } />

              // Añadir la nueva ruta justo después de la ruta de details
              <Route path="/producer/:blockchain/:producer/validations" element={
                <DashboardLayout>
                  <ProducerValidations />
                </DashboardLayout>
              } />              

              {/* Protected routes */}
              <Route path="/user-dashboard" element={
                <ProtectedRoute>
                  <DashboardLayout>
                    <UserDashboard />
                  </DashboardLayout>
                </ProtectedRoute>
              } />

              <Route path="/subscriptions" element={
                <ProtectedRoute>
                  <DashboardLayout>
                    <Subscriptions />
                  </DashboardLayout>
                </ProtectedRoute>
              } />            

              {/* Catch all route */}
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </Router>
        </LoginModalProvider>
      </NetworkProvider>
    </AuthProvider>
  );
}

export default App;