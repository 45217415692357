import React, { useState, useRef, useEffect } from 'react';
import { ChevronDown, Menu, X } from 'lucide-react';
import { 
  networks, 
  getMainnetNetworks, 
  getTestnetNetworks, 
  getDefaultNetwork 
} from '../config/networks';
import { useNetwork } from '../context/networkContext';
import { useAuth } from '../hooks/useAuth';
import { useLoginModal } from '../context/LoginModalContext';  // Añadimos esta importación
import { LoginForm } from '../components/auth/LoginForm';
import { useNavigate } from 'react-router-dom';
import { RegisterForm } from '../components/auth/RegisterForm';
import RequestResetForm from '../components/auth/RequestResetForm';

const NetworkOption = ({ network, onClick, isActive }) => (
  <button
    onClick={() => onClick(network)}
    className={`flex items-center w-full px-4 py-3 text-left hover:bg-gray-50
      ${isActive ? 'bg-purple-50' : ''}`}
  >
    <div className="flex items-center space-x-3">
      <img
        src={network.logo}
        alt={network.name}
        className="w-6 h-6 object-contain"
        onError={(e) => {
          e.target.src = 'https://via.placeholder.com/24';
        }}
      />
      <span className="text-gray-900">{network.name}</span>
    </div>
  </button>
);

const NetworkSelector = ({ isOpen, setIsOpen }) => {
  const { currentNetwork, setCurrentNetwork } = useNetwork();
  const mainnetNetworks = getMainnetNetworks();
  const testnetNetworks = getTestnetNetworks();

  return (
    <div className="relative">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center space-x-3 px-4 py-2 rounded-lg bg-white/10 border border-white/20 
          text-white hover:bg-white/20 transition-colors"
      >
        {currentNetwork && (
          <img
            src={currentNetwork.logo}
            alt={currentNetwork.name}
            className="w-6 h-6 object-contain"
            onError={(e) => {
              e.target.src = 'https://via.placeholder.com/24';
            }}
          />
        )}
        <span className="hidden md:block">{currentNetwork ? currentNetwork.name : 'Select Network'}</span>
        <ChevronDown size={16} />
      </button>

      {isOpen && (
        <div className="absolute right-0 mt-2 w-64 bg-white rounded-lg shadow-lg py-2 z-50">
          <div className="px-4 py-2">
            <h3 className="text-xs font-semibold text-gray-500 uppercase">Mainnet</h3>
          </div>
          <div className="space-y-1">
            {mainnetNetworks.map((network) => (
              <NetworkOption
                key={network.id}
                network={network}
                onClick={(network) => {
                  setCurrentNetwork(network);
                  setIsOpen(false);
                }}
                isActive={currentNetwork && currentNetwork.id === network.id}
              />
            ))}
          </div>
          
          {testnetNetworks.length > 0 && (
            <>
              <div className="px-4 py-2 mt-2 border-t">
                <h3 className="text-xs font-semibold text-gray-500 uppercase">Testnet</h3>
              </div>
              <div className="space-y-1">
                {testnetNetworks.map((network) => (
                  <NetworkOption
                    key={network.id}
                    network={network}
                    onClick={(network) => {
                      setCurrentNetwork(network);
                      setIsOpen(false);
                    }}
                    isActive={currentNetwork && currentNetwork.id === network.id}
                  />
                ))}
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

const UserMenu = () => {
  const { user, logout } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    setIsOpen(false);
    navigate('/dashboard');
  };

  return (
    <div className="relative">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center space-x-2 px-4 py-2 rounded-lg bg-white/10 border border-white/20 
          text-white hover:bg-white/20 transition-colors"
      >
        <span className="hidden md:inline">{user?.email}</span>
        <ChevronDown size={16} />
      </button>

      {isOpen && (
        <div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg py-1 z-50">
          <div className="px-4 py-2 text-sm text-gray-700 border-b">
            {user?.email}
          </div>
          <button
            onClick={handleLogout}
            className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
          >
            Logout
          </button>
        </div>
      )}
    </div>
  );
};

const LoginButton = () => {
  const { user } = useAuth();
  const { openModal } = useLoginModal();

  if (user) {
    return <UserMenu />;
  }

  return (
    <button 
      onClick={() => openModal('login')}
      className="flex items-center space-x-2 px-4 py-2 rounded-lg bg-white/10 border border-white/20 
        text-white hover:bg-white/20 transition-colors"
    >
      <span className="hidden md:inline">Login</span>
      <span className="md:hidden">👤</span>
    </button>
  );
};

function Header({ setSidebarOpen }) {
  const [isNetworkDropdownOpen, setIsNetworkDropdownOpen] = useState(false);
  const { currentNetwork, setCurrentNetwork } = useNetwork();
  const dropdownRef = useRef(null);

  useEffect(() => {
    if (!currentNetwork) {
      setCurrentNetwork(getDefaultNetwork());
    }
  }, [currentNetwork, setCurrentNetwork]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsNetworkDropdownOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <header className="sticky top-0 z-10 bg-gradient-to-r from-purple-600 to-indigo-600 shadow-lg">
      <div className="h-16 px-4 flex items-center justify-between">
        <button
          className="lg:hidden p-2 rounded-md text-white hover:bg-white/10"
          onClick={() => setSidebarOpen(true)}
        >
          <Menu size={24} />
        </button>

        <div className="flex-1" />

        <div className="flex items-center space-x-4" ref={dropdownRef}>
          <NetworkSelector
            isOpen={isNetworkDropdownOpen}
            setIsOpen={setIsNetworkDropdownOpen}
          />
          <LoginButton />
        </div>
      </div>
    </header>
  );
}

export default Header;