import React, { useState, useEffect } from 'react';
import { 
  Activity,
  Radio, 
  Server, 
  History, 
  Database, 
  Box,
  Search
} from 'lucide-react';
import { useNetwork } from '../context/networkContext';
import ApiService from '../services/api';
import { Link } from 'react-router-dom';

// Reuse SearchInput component
const SearchInput = ({ value, onChange }) => (
  <div className="relative">
    <div className="absolute inset-y-0 left-3 flex items-center pointer-events-none">
      <Search size={20} className="text-gray-400" />
    </div>
    <input
      type="text"
      placeholder="Search producer..."
      className="w-64 pl-10 pr-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500"
      value={value}
      onChange={(e) => onChange(e.target.value)}
    />
  </div>
);

// Component to animate the number
const AnimatedNumber = ({ value }) => {
  const [displayValue, setDisplayValue] = useState(value);
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    setIsAnimating(true);
    const startValue = displayValue;
    const endValue = value;
    const duration = 800;
    const startTime = performance.now();

    const animate = (currentTime) => {
      const elapsed = currentTime - startTime;
      const progress = Math.min(elapsed / duration, 1);

      // Easing function for smoother animation
      const easeOutQuad = progress => 1 - (1 - progress) * (1 - progress);
      
      const currentValue = startValue + (endValue - startValue) * easeOutQuad(progress);
      
      setDisplayValue(currentValue);

      if (progress < 1) {
        requestAnimationFrame(animate);
      } else {
        setIsAnimating(false);
      }
    };

    requestAnimationFrame(animate);
  }, [value]);

  return (
    <span className={`transition-opacity duration-800 ${
      isAnimating ? 'opacity-50' : 'opacity-100'
    }`}>
      {displayValue.toFixed(1)}%
    </span>
  );
};

const serviceConfig = {
  seed: {
    icon: <Radio size={32} />,
    name: 'Seed'
  },
  api: {
    icon: <Server size={32} />,
    name: 'API'
  },
  v1: {
    icon: <History size={32} />,
    name: 'V1'
  },
  v2: {
    icon: <Database size={32} />,
    name: 'V2'
  },
  atomic: {
    icon: <Box size={24} />,
    name: 'Atomic'
  }
};

const TimeframeButton = ({ active, children, onClick }) => (
  <button
    onClick={onClick}
    className={`px-3 py-1 md:px-4 md:py-2 rounded-lg font-medium transition-colors whitespace-nowrap ${
      active 
        ? 'bg-indigo-600 text-white' 
        : 'bg-white text-gray-600 hover:bg-gray-50'
    }`}
  >
    {children}
  </button>
);

const getStatusColor = (percentage) => {
  if (percentage >= 99) return '#22c55e';
  if (percentage >= 95) return '#4ade80';
  if (percentage >= 90) return '#fbbf24';
  if (percentage >= 85) return '#fb923c';
  if (percentage >= 80) return '#f87171';
  return '#ef4444';
};

const ServiceStatus = ({ type, uptime }) => {
  const percentage = Math.min(100, Math.max(0, uptime));
  const color = getStatusColor(percentage);
  const [isAnimating, setIsAnimating] = useState(false);
  
  useEffect(() => {
    setIsAnimating(true);
    const timer = setTimeout(() => {
      setIsAnimating(false);
    }, 800); // Same duration as AnimatedNumber

    return () => clearTimeout(timer);
  }, [uptime]);
  
  return (
    <div className="flex flex-col items-center">
      <div 
        className={`relative transition-all duration-800 ${
          isAnimating ? 'opacity-50 scale-95' : 'opacity-100 scale-100'
        }`} 
        style={{ color }}
      >
        {serviceConfig[type].icon}
      </div>
      <span 
        className={`text-xs mt-1 font-medium transition-all duration-800 ${
          isAnimating ? 'opacity-50' : 'opacity-100'
        }`}
        style={{ color }}
      >
        {serviceConfig[type].name}
      </span>
      <span className="text-sm font-bold mt-1" style={{ color }}>
        <AnimatedNumber value={percentage} />
      </span>
    </div>
  );
};

const ProducerRow = ({ producer, timeframe }) => {
  const { currentNetwork } = useNetwork(); // Import the hook here
  const countryCode = producer.country?.toLowerCase() || 'un';
  const uptimes = producer.uptimes[timeframe];
  const currentDays = timeframe.replace('d', ''); // Convert '7d' to '7', '30d' to '30', etc.
  
  return (
    <div className="bg-white rounded-lg p-4 shadow-sm hover:shadow-md transition-shadow">
      <div className="grid grid-cols-6 md:grid-cols-12 items-center">
        <div className="col-span-6 md:col-span-5 flex items-center gap-2 md:gap-4">
          <span className="text-gray-500 font-medium w-8">#{producer.position}</span>
          <div className="w-8 h-8 md:w-10 md:h-10 overflow-hidden rounded-lg flex items-center justify-center bg-indigo-50">
            {producer.logo ? (
              <img 
                src={producer.logo} 
                alt={producer.name}
                className="w-full h-full object-contain"
              />
            ) : (
              <span className="text-indigo-500 font-semibold">PR</span>
            )}
          </div>
          <div className="flex items-center gap-1 md:gap-2">
            <Link 
              to={`/producer/${currentNetwork.id}/${producer.name}/details?days=${currentDays}`}
              className="font-medium text-gray-900 hover:text-indigo-600 transition-colors"
            >
              {producer.name}
            </Link>
            {producer.isTop21 && (
              <span className="px-2 py-0.5 text-xs font-medium text-blue-600 bg-blue-50 rounded-full">
                Top 21
              </span>
            )}
            {countryCode !== 'un' && (
              <img 
                src={`https://flagcdn.com/20x15/${countryCode}.png`}
                alt={producer.country}
                className="h-4"
              />
            )}            
          </div>
        </div>

        <div className="col-span-6 md:col-span-7 grid grid-cols-2 md:grid-cols-5 gap-2 md:gap-4">
          {Object.entries(serviceConfig).map(([service]) => (
            <ServiceStatus 
              key={service}
              type={service}
              uptime={uptimes[service]}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

const Statistics = () => {
  const { currentNetwork } = useNetwork();
  const [producers, setProducers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [timeframe, setTimeframe] = useState('7d');
  const [searchTerm, setSearchTerm] = useState('');

  const network = currentNetwork?.id || 'wax-mainnet';

  useEffect(() => {
    const fetchProducers = async () => {
      try {
        setLoading(true);
        const data = await ApiService.getBPStatistics(network);
        setProducers(data.producers);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchProducers();
  }, [network]);

  const handleTimeframeClick = (newTimeframe) => {
    setTimeframe(newTimeframe);
  };

  // Filter producers based on search term
  const filteredProducers = producers.filter(producer =>
    producer.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <Activity className="animate-spin h-8 w-8 text-indigo-500" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-red-500 text-center p-4">
        Error loading producers: {error}
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {/* Fixed Header Section */}
      <div 
        className="flex items-center justify-between flex-wrap gap-2 md:gap-3 sticky top-0 bg-white p-4 shadow-md z-10"
        style={{ position: 'sticky', top: 0 }}
      >
        <div className="flex items-center gap-2 md:gap-3 flex-wrap">
          <TimeframeButton 
            active={timeframe === '7d'} 
            onClick={() => handleTimeframeClick('7d')}
          >
            7 days
          </TimeframeButton>
          <TimeframeButton 
            active={timeframe === '30d'} 
            onClick={() => handleTimeframeClick('30d')}
          >
            30 days
          </TimeframeButton>
          <TimeframeButton 
            active={timeframe === '45d'} 
            onClick={() => handleTimeframeClick('45d')}
          >
            45 days
          </TimeframeButton>
        </div>

        <SearchInput 
          value={searchTerm}
          onChange={setSearchTerm}
        />
      </div>

      {/* Producers List Section */}
      <div className="space-y-3">
        {filteredProducers.map(producer => (
          <ProducerRow 
            key={producer.name}
            producer={producer}
            timeframe={timeframe}
          />
        ))}
      </div>

      {filteredProducers.length === 0 && (
        <div className="text-center text-gray-500 py-8">
          No producers found matching your criteria
        </div>
      )}
    </div>
  );
};

export default Statistics;
