import React, { createContext, useContext, useState } from 'react';

const LoginModalContext = createContext(null);

export function LoginModalProvider({ children }) {
  const [isOpen, setIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState('login');  // 'login', 'register', or 'forgot-password'

  const openModal = (content = 'login') => {
    setModalContent(content);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setModalContent('login');  // Reset to login when closing
  };

  return (
    <LoginModalContext.Provider 
      value={{ 
        isOpen, 
        modalContent, 
        openModal, 
        closeModal, 
        setModalContent 
      }}
    >
      {children}
    </LoginModalContext.Provider>
  );
}

export const useLoginModal = () => {
  const context = useContext(LoginModalContext);
  if (!context) {
    throw new Error('useLoginModal must be used within a LoginModalProvider');
  }
  return context;
};