import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { useLoginModal } from '../context/LoginModalContext';
import DashboardLayout from '../components/DashboardLayout';

export const ProtectedRoute = ({ children }) => {
  const { user, loading } = useAuth();
  const { openModal } = useLoginModal();
  const navigate = useNavigate();
  
  useEffect(() => {
    if (!loading && !user) {
      openModal('login');
      navigate('/', { replace: true });
    }
  }, [user, loading, openModal, navigate]);
  
  if (loading) {
    return (
      <DashboardLayout>
        <div>Loading...</div>
      </DashboardLayout>
    );
  }
  
  if (!user) {
    return (
      <DashboardLayout>
        <div className="min-h-screen bg-gray-100" />
      </DashboardLayout>
    );
  }

  return children;
};

export default ProtectedRoute;